@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@400;500;600;700&family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

:root {
  --pri-font-: 'Montserrat', sans-serif;
  --sec-font-: 'Cormorant', serif;
}

.App {
  text-align: center;
}

nav {
  padding: 30px 0;
  background-color: #fff;
}

.container-xl {
  max-width: 100%;
}

.container-l {
  width: 1200px;
  margin: 0 auto;
}

header {
  background-color: #AEC5DF;
  padding: 40px 0
}

.header-image {
  max-width: 100%;
}

.header-card {
  background-color: #fff;
  text-align: left;
  padding: 20px;
  border-radius: 6px;
}

.header-title {
  font-family: var(--sec-font-);
}
.header-text {
  font-family: var(--sec-font-);
}

/* mobile */
.header-container {
  place-items: center;
}

/* desktop */
@media screen and (min-width: 768px) {
  header {
    padding: 0;
  }

  .header-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
  }

  .header-image {
    justify-self: end;
  }

  .header-card {
    justify-self: end;
    margin: 0 -50px 0 0;
    z-index: 1;
  }
}

.header-button {
  text-decoration: none;
  background-color: rgb(183, 149, 114);
  color: #fff;
  border: none;
  font-size: 0.8rem;
  padding: 8px 16px;
  font-family: var(--pri-font-);
  font-weight: 600;
  border-radius: 6px;
}

.about {
  padding: 40px 0;
  font-family: var(--sec-font-);
  grid: 40px;
}

.about-text-group {
  text-align: left;
}

.about-image {
  margin: 20px 0;
}

/* desktop */
@media screen and (min-width: 768px) {
  .about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 40px;
  }
}

.about-image {
  max-width: 100%;
}

.amenities-group {
  padding: 20px 0;
  margin-top: 40px;
  background-color: #f1efef;
}

.amen-item {
  text-align: left;
}

.amen-item-text {
  font-family: var(--sec-font-);
}

.amen-image {
  max-width: 100%;
}
/* desktop */
@media screen and (min-width: 768px) {
  .amen-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    place-items: center;
  }
}

.interior-image {
  max-width: 100%;
}

.interior {
  background-color: rgb(183, 149, 114);
  padding: 20px 0;
}

.interior-text {
  text-align: left;
  font-family: var(--sec-font-);
}

.interior-text ul {
  margin: 0;
  padding: 0
}

@media screen and (min-width: 768px) {
  .interior-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
  }
}

.gallery {
  padding: 40px 0;
}


@media screen and (min-width: 768px) {
  .pricing-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.pricing {
  padding: 40px 0;
  background-color: #aec5df;
}

.pricing-title {
  font-family: var(--sec-font-);
  font-size: 1.2rem;
  color: rgb(159, 125, 100);
  font-weight: 600;
}

.pricing-text {
  margin: 5px 0;
  font-family: var(--pri-font-);
  font-size: 0.8rem;
  font-weight: 500;
  color: #4d4d4d;
}

.pricing-item {
  background-color: #fff;
  margin: 10px;
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.documentation, .connection {
  font-family: var(--pri-font-);
  font-weight: 500;
}

/* form */
.form-section {
  padding: 40px 0;
  font-family: var(--pri-font-);
}

.form-title {
  font-size: 1.5rem;
  font-family: var(--sec-font-);
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 40px;
}

input[type=text] {
  width: 100%;
  padding: 8px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=tel] {
  width: 100%;
  padding: 8px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=email] {
  width: 100%;
  padding: 8px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.select-text {
  width: 100%;
  padding: 8px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: rgb(183, 149, 114);
  color: white;
  padding: 8px 12px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

label {
  text-align: left;
  width: 100%;
}

input[type=submit]:hover {
  background-color: #4d4d4d;
}

.form-parent {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.success-message {
  background-color: #00994d;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 6px;
  padding: 10px 16px;
  color: #fff;
  width:max-content;
  text-align: center;
}

.error-message {
  background-color: crimson;
  padding: 10px;
  color: #fff;
  width:max-content;
}

.developer-logo {
  max-width: 100%;
}

.about-developer-text {
  font-size: 0.8rem;
  width: 450px;
  margin: 30px auto;
}

.developer-link {
  font-size: 0.8rem;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .about-developer-text {
    width: 100%;
  }
}

footer {
  background-color: black;
  padding: 40px 0;
}

.footer-right {
  color: #AEC5DF;
}

.footer-link {
  text-decoration: none;
  color: #ffb300;
}